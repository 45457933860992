import React from "react";
import UseData from "../Hooks/UseData";

const Footer = ({ bg, condition }) => {
  const { local } = UseData();
  return (
    <footer
      style={{
        background: `${
          local === "light" ? bg : condition ? "#212425" : "transparent"
        }`,
      }}
      className="overflow-hidden rounded-b-2xl"
    >
      <p className="text-center py-6 text-gray-lite  dark:text-color-910 ">
        © 2022. No part of this website shall be seen as solicitation, advertisement, or inducement under Section 49(1)(c) of the Advocates Act 1961.
        {/*  {" "}
        <a
          className="hover:text-[#FA5252] duration-300 transition"
          href="https://yash.gg"
          target="_blank"
          rel="noopener noreferrer"
        >
          Yashendra Shukla
        </a>
        .
         */}
      </p>
    </footer>
  );
};

export default Footer;
