import { useEffect, useState } from "react";
//  icon use as img here
import icon from "../assets/images/icons/icon.svg";
import icon1 from "../assets/images/icons/icon (1).svg";
import icon2 from "../assets/images/icons/icon (2).svg";
import icon3 from "../assets/images/icons/icon (3).svg";
import icon4 from "../assets/images/icons/icon (4).svg";
import icon5 from "../assets/images/icons/icon (5).svg";
import { CgNotes } from "react-icons/cg";
import { FaRegUser } from "react-icons/fa";
import { AiOutlineHome } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";

import { MdOutlineBusinessCenter, MdOutlineSchool } from "react-icons/md";

const AllData = () => {
  const [check, setCheck] = useState(false);
  const [local, setLocal] = useState(localStorage.getItem("theme"));
  const [singleData] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  // dark and light theme controls
  useEffect(() => {
    const themeValue = localStorage?.getItem("theme");

    if (!themeValue) {
      setCheck(false);
      localStorage.setItem("theme", "dark");
    } else {
      themeValue === "dark" && setCheck(true);
      themeValue === "light" && setCheck(false);
    }

    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  }, []);

  // Create and light theme function
  const handleTheme = (value) => {
    if (value === "light") {
      setCheck(false);
      localStorage.setItem("theme", "dark");
      setLocal("dark");
    } else {
      setCheck(true);
      localStorage.setItem("theme", "light");
      setLocal("light");
    }
    localStorage?.getItem("theme") === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
  };

  // Active navlinks function
  function NavLink({
    to,
    className,
    activeClassName,
    inactiveClassName,
    ...rest
  }) {
    let location = useLocation();
    let isActive = location.pathname === to;
    let allClassNames =
      className + (isActive ? `${activeClassName}` : `${inactiveClassName}`);
    return <Link className={allClassNames} to={to} {...rest} />;
  }

  // Menu items for Homepage One
  const menuItem = [
    {
      id: "01",
      name: "Home",
      link: "/",
      icon: <AiOutlineHome />,
    },
    {
      id: "02",
      name: "About",
      link: "/about",
      icon: <FaRegUser />,
    },
    {
      id: "06",
      name: "Resume",
      link: "/resume",
      icon: <CgNotes />,
    },
/*     {
      id: "03",
      name: "Works",
      link: "/home/works",
      icon: <FiCodesandbox />,
    }, */
/*     {
      id: "04",
      name: "Blogs",
      link: "/home/blogs",
      icon: <FaBlogger />,
    },
    {
      id: "05",
      name: "Contact",
      link: "/home/contact",
      icon: <RiContactsBookLine />,
    }, */
  ];

  // experience items for about page
  const experienceArray = [
    {
      id: "1",
      icon: icon,
      title: "Ui/Ux Design",
      des: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam euismod volutpat.",
      color: "#D566FF",
      bg: "#FCF4FF",
    },
    {
      id: "2",
      icon: icon1,
      title: "App Development",
      des: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam euismod volutpat.",
      color: "#DDA10C",
      bg: "#FEFAF0",
    },
    {
      id: "3",
      icon: icon2,
      title: "Photography",
      des: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam euismod volutpat.",
      color: "#8774FF",
      bg: "#FCF4FF",
    },
    {
      id: "4",
      icon: icon3,
      title: "Photography",
      des: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam euismod volutpat.",
      color: "#FF6080",
      bg: "#FFF4F4",
    },
    {
      id: "5",
      icon: icon4,
      title: "Managment",
      des: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam euismod volutpat.",
      color: "#FF75D8",
      bg: "#FFF0F8",
    },
    {
      id: "6",
      icon: icon5,
      title: "Web Development",
      des: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam euismod volutpat.",
      color: "#269FFF",
      bg: "#F3FAFF",
    },
  ];

  // Resume items for Resume page
  const resumeArray = [
    {
      type: "Education",
      icon: MdOutlineSchool,
      id: "01",
      date: "2015-2021",
      title: "Bachelor of Arts - Bachelor of Legislative Law",
      place: "Chaudhary Charan Singh University | Meerut, India",
      bg: "#FFF4F4",

      id1: "02",
      date1: "2010 - 2014",
      title1: "CBSE, AISSCE - Higher Secondary School",
      place1: "Amity International School, Saket | New Delhi, India",
      bg1: "#FFF1FB",

      id2: "03",
      date2: "2004 - 2010",
      title2: "CBSE - Lower Secondary School",
      place2: "Kendriya Vidyalaya, IIT Powai | Mumbai, India",
      bg2: "#FFF4F4",
    },
    {
      type: "Experience",
      icon: MdOutlineBusinessCenter,
      id: "04",
      date: "2020 - Present",
      title: "YouTube/Twitch Streamer",
      place: "Shuklaji/SneakyShukla | Mumbai, India",
      bg: "#EEF5FA",

      id1: "05",
      date1: "2020 - 2022",
      title1: "Legal Advisior",
      place1: "Cleanscape Housekeeping Services | Goa, India",
      bg1: "#F2F4FF",

      id2: "06",
      date2: "2018 - 2018",
      title2: "Event Organizer - Technical & IT",
      place2: "Frappe Technologies Pvt. Ltd | Mumbai, India",
      bg2: "#EEF5FA",
    },
    {
      type: "Experience",
      icon: MdOutlineBusinessCenter,
      id: "07",
      date: "2015 - 2016",
      title: "Intern",
      place: "Moolchand Hospital | New Delhi, India",
      bg: "#EEF5FF",

      id1: "08",
      date1: "2014 - 2015",
      title1: "Intern",
      place1: "Triton Foodworks | New Delhi, India",
      bg1: "#EEF5FA",
    },
  ];

  // Working Skills items for Resume page
  const lineArray = [
    {
      id: "01",
      color: "#FF6464",
      name: "Web Design",
      number: "80",
    },
    {
      id: "02",
      color: "#9272D4",
      name: "Mobile App ",
      number: "95",
    },
    {
      id: "03",
      color: "#5185D4",
      name: "Illustrator",
      number: "65",
    },
    {
      id: "03",
      color: "#CA56F2",
      name: "Photoshope",
      number: "75",
    },
  ];

  // Personal information for contact pages | Removed
/*   const contactArray = [
    {
      id: "01",
      icon: iconPhone,
      title: "Phone ",
      item1: "+91 8383031323",
      //item2: "+91 8383031323",
      bg: "#FCF4FF",
    },
    {
      id: "02",
      icon: iconEmail,
      title: "Email ",
      item1: "hello@yash.gg",
      //item2: "hello@yash.gg",
      bg: "#EEFBFF",
    },
    {
      id: "03",
      icon: iconMap,
      title: "Location ",
      item1: "Mumbai, India",
      //item2: "Road, New York, USA",
      bg: "#F2F4FF",
    },
  ]; */

  return {
    handleTheme,
    check,
    local,
    // handleData,
    // data,
    singleData,
    // handleModelData,
    isOpen,
    setIsOpen,
    // blogsData,
    // handleBlogsData,
    menuItem,
    NavLink,
    // menuItemTwo,
    experienceArray,
    // sliderImg,
    resumeArray,
    lineArray,
    // contactArray,
  };
};

export default AllData;
